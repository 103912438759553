module.exports = [{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["IsonormRegular","ADRegular"]}},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oopsy Daisy Baby","short_name":"Oopsy Daisy Baby","start_url":"/","background_color":"#fff8f0","theme_color":"#97b498","display":"standalone","icon":"static/images/logo.png"},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
